.services {
    width: 100%;
}

.services-header {
    width: 100%;
    min-height: 43vh;
    background-image: url("../images/backgroung/servicesBg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
}

.services-header > h1 {
    width: 20%;
    margin: 0;
    padding: 2% 3% 0.5% 3%;
    background-color: white;
    color: #292f36;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.services-header > h1 > p {
    color: #4d5053;
    font-size: 14px;
    font-family: Jost;
}

/* SERVICE TYPES */
.service-types {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    margin: 5% auto;
    align-items: stretch; /* Добавлено для выравнивания по высоте */
}

.service-type {
    width: 100%;
    display: flex;
    flex: 1 1 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 3%;
    text-align: center;
    border-radius: 20px;
    height: 100%; /* Добавлено для выравнивания по высоте */
}

.selected-type {
    background-color: #F4F0EC;
}

.service-type > h2 {
    font-family: 'Dm Serif Display';
    margin: 0;
    color: #292f36;
    min-height: 60px; /* Установлено минимальное значение высоты для заголовков */
}

.service-type > p {
    font-family: 'Jost';
    color: #4d5053;
}

.service-type > a {
    width: 50%;
}

.service-type > a > button {
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Jost';
    font-size: 14px;
    font-weight: 600;
    color: #292f36;
}

/* HOW WE WORK */
.howWeWork {
    color: #292f36;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3% auto;
    background-color: #F4F0EC;
    border-radius: 30px;
    padding: 7% 5%;
}

.how-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 7% 0% 3% 0%;
}

.hc-img {
    width: 50%;
    object-fit: cover;
}

.hc-img > img {
    width: 100%;
    border-top-right-radius: 25%;
    border-bottom-left-radius: 70%;
}

.how-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 90%;
}

.how-title > h1 {
    margin: 0;
}

.how-title > p {
    font-family: 'Jost';
    color: #4d5053;
}

.hc-text {
    margin: 0% 5%;
    width: 45%;
}

.hc-text-anima {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 5% 0;
}

.hc-text-anima > img {
    width: 25%;
}

.hc-text-anima > p {
    font-size: 20vh;
    font-family: 'Dm Serif Display';
    margin: 0;
    color: white;
}

.hc-subtext > p {
    color: #4d5053;
    font-size: 14px;
}

/* INTERNO */
.interno {
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 10% auto;
    padding: 3% 0;
    background-color: #292F36;
    border-radius: 50px;
    color: white;
}

.interno > h1 {
    margin: 0;
    color: white;
}

.interno > a {
    width: 22%;
    margin: 3% 0%;
}

.interno > a > button {
    color: white;
    width: 100%;
    padding: 8% 0%;
    border-radius: 15px;
    border: none;
    background-color: #CDA274;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Основные стили для больших экранов остаются неизменными */

/* Mobile and Tablet Styles */
@media screen and (max-width: 768px) {
    .services-header {
        min-height: 30vh;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 0; /* Убираем отступ снизу */
        position: relative;
    }

    .services-header::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-color: white;
        z-index: 1;
    }

    .services-header > h1 {
        width: 80%; /* Немного сузить */
        padding: 10px;
        font-size: 22px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 0; /* Убираем закругление снизу */
        border-bottom-left-radius: 0; /* Убираем закругление снизу */
        margin: -20px auto 0 auto; /* Опустить вниз */
        z-index: 2;
        position: relative;
    }

    .services-header > h1 > p {
        font-size: 16px;
    }

    .service-types {
        width: 90%;
        margin: 5% auto;
        flex-direction: column;
        align-items: center;
    }

    .service-type {
        width: 100%;
        margin-bottom: 10px; /* Уменьшаем нижний отступ */
        padding: 5% 3%; /* Уменьшаем внутренние отступы */
    }

    .service-type > h2 {
        font-size: 20px;
    }

    .service-type > p {
        font-size: 14px;
    }

    .service-type > a {
        width: 80%;
        margin-top: 5px; /* Уменьшаем верхний отступ */
    }

    .service-type > a > button {
        font-size: 16px;
        padding: 8px; /* Уменьшаем внутренние отступы */
    }

    .howWeWork {
        width: 90%;
        padding: 10% 5%;
    }

    .how-content {
        flex-direction: column;
        align-items: center;
        margin: 7% 0 3% 0;
        text-align: center; /* Центрируем текст */
    }

    /* Переставляем порядок элементов */
    .hc-img {
        width: 100%;
        order: 4; /* Изображение должно быть последним */
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hc-img img {
        width: 70%; /* Уменьшаем изображение */
        border-radius: 15px; /* Немного уменьшаем закругления */
    }

    .hc-text {
        width: 100%;
        margin: 0;
        text-align: center; /* Центрируем текст */
    }

    .hc-text-anima {
        justify-content: center;
        margin-bottom: 20px;
        order: 1; /* Цифра и иконка идут первыми */
    }

    .hc-text-anima > img {
        width: 20%; /* Уменьшаем размер иконок */
    }

    .hc-text-anima > p {
        font-size: 12vh; /* Уменьшаем размер номера этапа */
    }

    .hc-subtext > h2 {
        font-size: 1.4rem;
        margin-bottom: 15px;
        order: 2; /* Заголовок после цифры и иконки */
    }

    .hc-subtext > p {
        font-size: 1rem;
        order: 3; /* Описание идет перед изображением */
    }

    .interno {
        width: 90%;
        padding: 15% 5%;
        margin: 20% auto;
        border-radius: 30px;
    }

    .interno > h1 {
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    .interno > a {
        width: 80%;
        margin: 20px auto;
    }

    .interno > a > button {
        font-size: 1.2rem;
        padding: 12px 0;
    }
}

@media screen and (max-width: 480px) {
    .services-header > h1 {
        font-size: 18px;
        padding: 8px;
        width: 85%; /* Немного сузить */
        margin: -20px auto 0 auto; /* Опустить вниз */
    }

    .services-header > h1 > p {
        font-size: 14px;
    }

    .service-types {
        width: 95%;
    }

    .service-type {
        padding: 8% 3%; /* Уменьшаем внутренние отступы */
        margin-bottom: 10px; /* Уменьшаем нижний отступ */
    }

    .service-type > h2 {
        font-size: 18px;
    }

    .service-type > p {
        font-size: 14px;
    }

    .service-type > a > button {
        font-size: 14px;
        padding: 8px; /* Уменьшаем внутренние отступы */
    }

    .howWeWork {
        width: 95%;
        padding: 12% 5%;
    }

    /* Переставляем порядок элементов */
    .hc-img img {
        width: 100%; /* Уменьшаем изображение для мобильных устройств */
        border-radius: 10px; /* Ещё немного уменьшаем закругления */
        order: 4; /* Изображение должно быть последним */
        margin-top: 20px;
    }

    .hc-text-anima > p {
        font-size: 10vh; /* Ещё больше уменьшаем размер номера этапа */
        order: 1; /* Цифра и иконка идут первыми */
    }

    .hc-subtext > h2 {
        font-size: 1.2rem;
        order: 2; /* Заголовок после цифры и иконки */
    }

    .hc-subtext > p {
        font-size: 0.9rem;
        order: 3; /* Описание идет перед изображением */
    }

    .interno {
        width: 95%;
        padding: 20% 5%;
        margin: 30% auto;
        border-radius: 20px;
    }

    .interno > h1 {
        font-size: 1.4rem;
    }

    .interno > a > button {
        font-size: 1rem;
        padding: 10px 0;
    }
}
