/* Базовые стили для больших экранов */
.about {
    width: 100%;
}

/* Header */
.about-header {
    display: flex;
    height: 42vh;
    font-family: 'DM Serif Display';
    background-repeat: no-repeat;
    background-image: url("../images/backgroung/aboutBg.png");
    margin-bottom: 10%;
    background-size: cover;
}

.about-header > h1 {
    width: 20%;
    padding: 2% 3% 0.5% 3%;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    align-self: flex-end;
    text-align: center;
    margin: 0 auto;
    color: #292F36;
}

.about-header > h1 > p {
    color: #4D5053;
    font-size: 14px;
    font-family: Jost;
}

/* QUOTES */
.quotes {
    width: 100%;
    margin: 5% auto;
    display: flex;
    justify-content: center;
    text-align: center;
}

.content {
    width: 30%;
}

.quotes > h1,
.content > .comment {
    font-family: "Dm Serif Display";
    font-style: italic;
    margin: 0;
}

.quotes > h1 {
    font-size: 40px;
}

.content > .comment {
    font-size: 18px;
}

.author {
    font-size: 12px;
    font-family: "Jost";
    padding-top: 3%;
}

.pattern {
    width: 10%;
    border: 15px solid #F4F0EC;
    border-radius: 20%;
}

.second {
    border-top-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    border-right-style: groove;
}

.first {
    border-top-style: hidden;
    border-left-style: groove;
    border-right-style: hidden;
    border-bottom-style: hidden;
}

/* About concept */
.about-concept {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 8% 0%;
}

.con {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4% auto;
}

.c-text {
    width: 40%;
    margin: 0% 5%;
}

.c-text > p {
    font-family: "Jost";
    color: #4D5053;
}

.c-text > h1 {
    font-family: "Dm Serif Display";
    color: #292F36;
}

.c-text > button {
    width: 42%;
    padding: 5% 0%;
    margin-top: 8%;
    border-radius: 10px;
    border: none;
    background-color: #292F36;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Jost";
}

.concept-img {
    object-fit: cover;
    width: 50%;
}

.concept-img > img {
    width: 100%;
    border-radius: 40px;
}

/* Чередование блоков */
.firstPart .concept-img {
    order: 2;
}

.firstPart .c-text {
    order: 1;
}

.secondPart .concept-img {
    order: 1;
}

.secondPart .c-text {
    order: 2;
}

/* Creative-pro */
.creative-pro {
    width: 100%;
    text-align: center;
    margin-bottom: 8%;
}

.creative-pro > h1 {
    width: 32%;
    margin: 0 auto 5% auto;
}

.creMailBox {
    width: 50%;
    margin: 0 auto;
}

.creNameEmail > input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    outline: none;
    margin-left: 5%;
    border-bottom: 1px solid black;
    color: black;
}

.cre-text > textarea {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    outline: none;
    margin-left: 5%;
    resize: none;
    min-height: 20vh;
    width: 100%;
    color: black;
}

.creNameEmail {
    display: flex;
}

.creNameEmail > input {
    margin-bottom: 10%;
    width: 100%;
    height: 30px;
}

.cre-text {
    width: 100%;
}

.cre-send {
    width: 100%;
    margin-top: 8%;
    display: flex;
    justify-content: center;
}

.cre-send > button {
    width: 28%;
    padding: 3% 0%;
    border-radius: 15px;
    background-color: #292F36;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Адаптивные стили для мобильных устройств */
@media screen and (max-width: 768px) {
    .about-header {
        height: 30vh; /* Уменьшена высота блока */
        margin-bottom: 0; /* Убираем отступ снизу */
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
    }

    .about-header::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-color: white;
        z-index: 1;
    }

    .about-header > h1 {
        width: 80%; /* Увеличена ширина блока с заголовком */
        padding: 5px 10px; /* Уменьшены внутренние отступы */
        font-size: 4vh;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 0; /* Убираем закругление снизу */
        border-bottom-left-radius: 0; /* Убираем закругление снизу */
        margin-top: -5%;
        z-index: 2;
        position: relative;
    }

    .about-header > h1 > p {
        font-size: 12px;
    }

    .quotes {
        flex-direction: column;
        text-align: center;
    }

    .content {
        width: 90%;
    }

    .pattern {
        display: none;
    }

    .about-concept .con {
        width: 90%;
        flex-direction: column;
        text-align: center;
    }

    .c-text {
        width: 100%;
        margin: 0;
        text-align: center;
    }

    .c-text > button {
        width: 80%;
        margin-top: 20px;
    }

    .concept-img {
        width: 100%;
        margin-top: 20px;
    }

    .concept-img > img {
        border-radius: 20px;
    }

    .creative-pro > h1 {
        width: 80%;
        font-size: 4vh;
        margin: 0 auto;
    }

    .creMailBox {
        width: 90%;
    }

    .creNameEmail {
        flex-direction: column;
    }

    .creNameEmail > input {
        margin-left: 0;
        margin-bottom: 20px;
        width: 100%;
    }

    .cre-text > textarea {
        margin-left: 0;
        width: 100%;
    }

    .cre-send > button {
        width: 80%;
    }
}

/* Адаптивные стили для планшетов */
@media screen and (min-width: 481px) and (max-width: 1024px) {
    .about-header {
        height: 35vh;
        margin-bottom: 0; /* Убираем отступ снизу */
        position: relative;
    }

    .about-header::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-color: white;
        z-index: 1;
    }

    .about-header > h1 {
        width: 60%;
        font-size: 5vh;
        padding: 10px;
        border-radius: 20px;
        border-bottom-right-radius: 0; /* Убираем закругление снизу */
        border-bottom-left-radius: 0; /* Убираем закругление снизу */
        z-index: 2;
        position: relative;
    }

    .content {
        width: 50%;
    }

    .about-concept .con {
        width: 80%;
    }

    .c-text {
        width: 90%;
        text-align: center;
    }

    .c-text > button {
        width: 50%;
    }

    .concept-img {
        width: 90%;
    }

    .creative-pro > h1 {
        width: 60%;
        font-size: 5vh;
    }

    .creMailBox {
        width: 70%;
    }

    .cre-send > button {
        width: 50%;
    }
}
