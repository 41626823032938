.header {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed; /* Фиксированное положение хедера */
    top: 0; /* Прикрепляем хедер к верхней части страницы */
    left: 0;
    background-color: #fff; /* Фон хедера */
    z-index: 1000; /* Хедер всегда сверху */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Добавляем тень для визуального отделения хедера */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Плавный переход для фона и тени */
}

.header-scrolled {
    background-color: rgba(255, 255, 255, 0.8); /* Прозрачный фон */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Уменьшенная тень */
}

a {
    text-decoration: none;
    color: black;
}

.header-logo-text {
    font-family: 'DM Serif Display';
    font-size: 22px;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 13%;
}

.header-logo {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 150px;
    height: auto;
}

.header-logo > img {
    width: 100%;
    height: auto;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
}

.header-pages {
    width: 65%;
    display: flex;
}

.header-pages > ul {
    list-style-type: none;
    font-family: Jost;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    justify-content: space-between;
}

.header-pages > ul > li {
    margin-right: 3%;
}

.header-pages > ul > li a {
    transition: color 0.3s ease, text-decoration 0.3s ease; /* Плавный переход для цвета и подчеркивания */
}

.header-pages > ul > li a:hover {
    color: #007BFF; /* Цвет текста при наведении */
    text-decoration: underline; /* Подчеркивание текста при наведении */
}

@media (max-width: 768px) {
    body {
        padding-top: 60px; /* Увеличиваем отступ на мобильных устройствах, если хедер выше */
    }

    .header {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .header-logo-text {
        width: 100%;
        margin-left: 0;
        margin-bottom: 10px;
        justify-content: space-between;
    }

    .header-pages {
        width: 100%;
        display: none;
        flex-direction: column;
        align-items: center;
    }

    .header-pages > ul {
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    .header-pages > ul > li {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .menu-toggle {
        display: block;
        margin-right: 10px;
    }

    .header-pages.open {
        display: flex;
    }
}
