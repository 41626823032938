.contact {
    width: 100%;
}

.contact-header {
    display: flex;
    height: 42vh;
    font-family: 'DM Serif Display';
    background-repeat: no-repeat;
    background-image: url("../images/backgroung/contact-bg.jpg");
    margin-bottom: 10%;
    background-size: cover;
}

.contact-header>h1 {
    width: 20%;
    padding: 2% 3% 0.5% 3%;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    align-self: flex-end;
    text-align: center;
    margin: 0 auto;
    color: #292F36;
}

.contact-header>h1>p {
    color: #4D5053;
    font-size: 14px;
    font-family: Jost;
}

.contact-content {
    align-items: center;
    width: 70%;
    text-align: center;
    margin: 0 auto;
}

.contact-content>h2 {
    width: 50%;
    font-size: 30px;
    padding: 10% 0% 5% 0%;
    margin: 0 auto;
}

.contact-form {
    display: flex;
}

.contact-form-info {
    background-color: #F4F0EC;
    border-radius: 30px;
    padding: 5% 0%;
    width: 30%;
    margin: 0 auto;
}

.contact-form-info>div {
    text-align: center;
}

.contact-form-info>.icons>p {
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-bottom: 5%;
}

.icon {
    border: 1px solid white;
    color: #CDA274;
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
}

.contact-smedias {
    margin-top: 20%;
}

.contact-smedias>ul {
    display: flex;
    padding: 0;
}

.contact-smedias>ul>li {
    list-style-type: none;
    margin-left: 10%;
}

.contact-form-fill {
    width: 60%;
}

.contact-form-fill>div {
    display: flex;
}

.contact-form-fill>div>input,
.contact-form-fill>div>textarea,
.contact-form-fill>div .phone-input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    outline: none;
    margin-left: 5%;
    border-bottom: 1px solid black;
}

.contact-form-fill>div .phone-input {
    width: 100%;
    margin-left: 5%;
}

.contact-form-fill>div>textarea {
    resize: none;
    min-height: 100px;
    width: 100%;
}

.contact-form-fill>.nameEmail>input,
.contact-form-fill>.subjectPhone>input,
.contact-form-fill>.subjectPhone .phone-input {
    margin-bottom: 10%;
    width: 100%;
    height: 30px;
}

.contact-form-fill>.interested {
    width: 100%;
}

.contact-form-fill>.send {
    width: 100%;
    margin-top: 8%;
    display: flex;
    justify-content: flex-end;
}

.contact-form-fill>.send>button {
    width: 30%;
    padding: 3% 0%;
    border-radius: 15px;
    background-color: #292F36;
    color: white;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.map {
    display: flex;
    justify-content: center;
    margin: 8% 0%;
}

iframe {
    border-radius: 20px;
}

/* Адаптивность для мобильных устройств */
@media screen and (max-width: 480px) {

    .contact-header {
        height: 30vh;
    }

    .contact-header>h1 {
        width: 80%;
        padding: 10px 20px;
        font-size: 18px;
    }

    .contact-content {
        width: 90%;
    }

    .contact-content>h2 {
        width: 100%;
        font-size: 24px;
        padding: 10% 0 5% 0;
    }

    .contact-form {
        flex-direction: column;
        align-items: center;
    }

    .contact-form-info {
        width: 85%; /* Уменьшил ширину для лучшего размещения */
        padding: 8%; /* Уменьшил отступы */
        margin-bottom: 20px;
        text-align: left; /* Выровнять текст по левой стороне */
        box-sizing: border-box; /* Гарантирует, что отступы учитываются в общей ширине */
    }

    .contact-form-info>.icons>p {
        padding-left: 0;
        justify-content: flex-start; /* Выровнять по левой стороне */
    }

    .contact-smedias>ul {
        justify-content: flex-start; /* Выровнять по левой стороне */
    }

    .contact-smedias>ul>li {
        margin-left: 5%;
    }

    .contact-form-fill {
        width: 100%;
    }

    .contact-form-fill>div {
        flex-direction: column;
        margin: 0;
    }

    .contact-form-fill>div>input,
    .contact-form-fill>div .phone-input {
        margin-left: 0;
        margin-bottom: 10%;
        width: 100%;
    }

    .contact-form-fill>div>textarea {
        margin-left: 0;
        width: 100%;
    }

    .contact-form-fill>.send {
        justify-content: center;
    }

    .contact-form-fill>.send>button {
        width: 50%;
    }

    .map {
        margin: 10% 0;
    }

    iframe {
        width: 100%;
    }
}
