@media screen and (max-width: 768px) {
    /* Основные контейнеры */
    .home {
        padding: 0; /* Убраны отступы по бокам */
        overflow-x: hidden; /* Предотвращение горизонтального скроллинга */
        box-sizing: border-box;
    }

    /* HOME NEWS */
    .homeNews {
        height: auto;
        padding: 20px 5%;
        border-radius: 0 0 0 25%; /* Добавлено закругление нижнего левого угла */
        margin-bottom: 8%;
        background-position: center;
        background-size: cover; /* Изображение будет покрывать весь блок */
        box-sizing: border-box;
    }

    .homeNews > h1,
    .homeNews > p {
        width: 100%;
        box-sizing: border-box;
        word-wrap: break-word; /* Для предотвращения переполнения текста */
        text-align: center; /* Центрируем текст */
        margin: 0 auto;
    }

    .homeNews > a > button {
        width: 80%; /* Уменьшена ширина кнопки */
        padding: 10px; /* Уменьшены отступы */
        font-size: 16px; /* Уменьшен размер шрифта */
        background-color: #CDA274;
        color: #fff;
        border-radius: 25px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    /* HOME PLANS */
    .homePlans {
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .homePlan {
        width: 100%;
        margin-bottom: 20px;
        box-sizing: border-box;
    }

    .homePlan > a > button {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    /* HOME ABOUT US */
    .homeAboutUs {
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        text-align: center; /* Центрируем весь текст в блоке */
    }

    .homeAboutUs > .hp-subtext {
        width: 100%;
        box-sizing: border-box;
        text-align: center; /* Центрируем текст */
        margin: 0 auto;
    }

    .hp-img {
        width: 100%;
        margin-top: 20px;
        box-sizing: border-box;
        text-align: center; /* Центрируем изображение */
    }

    .hp-img > img {
        width: 100%;
        border-radius: 25%;
        box-sizing: border-box;
    }

    /* CALL US */
    .callUs {
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        text-align: center; /* Центрируем весь текст в блоке */
    }

    .callUs > .phoneNum {
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
        box-sizing: border-box;
        text-align: center; /* Центрируем текст */
    }

    .callUs > a {
        width: 100%;
        box-sizing: border-box;
    }

    .callUs > a > button {
        width: 80%; /* Уменьшена ширина кнопки */
        padding: 10px; /* Уменьшены отступы */
        box-sizing: border-box;
        margin: 0 auto;
    }

    /* PEOPLE THOUGHTS */
    .people-thoughts {
        padding: 5% 2%;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        text-align: center;
    }

    .people {
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .person {
        width: 90%;
        margin-bottom: 20px;
        padding: 15px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }

    .testimonial-arrow {
        font-size: 2rem;
        color: #CDA274;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
    }

    .testimonial-arrow.left {
        left: 5px; /* Корректируем позиционирование стрелок */
    }

    .testimonial-arrow.right {
        right: 5px; /* Корректируем позиционирование стрелок */
    }

    /* HOME PROJECTS */
    .hp-list {
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .hp-project {
        width: 100%;
        margin-bottom: 20px;
        box-sizing: border-box;
    }

    /* HOME EXPERIENCE */
    .home-experience {
        flex-direction: column;
        align-items: center;
        padding: 10% 5%;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
    }

    .home-experience > div {
        width: 100%;
        margin-bottom: 20px;
        box-sizing: border-box;
    }

    /* BRANDS */
    .home-brands {
        width: 100%;
        margin: 5% 0;
        box-sizing: border-box;
        text-align: center;
    }

    .home-brands > ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .home-brands > ul > ol {
        width: 30%;
        margin-bottom: 20px;
        box-sizing: border-box;
    }

    /* ARTICLES */
    .articles {
        flex-direction: column;
        align-items: center;
        padding: 0 5%;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .article {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 15px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }

    .article-header > img {
        width: 100%;
        border-radius: 15px;
        box-sizing: border-box;
    }

    .article-content {
        text-align: center;
        width: 100%;
        box-sizing: border-box;
    }

    /* INTERNO */
    .h-interno {
        width: 90%;
        margin: 5% auto;
        padding: 5% 2%;
        border-radius: 30px;
        box-sizing: border-box;
        text-align: center;
    }
}
