.serviceSingle {
    width: 100%;
}

.serviceS-header {
    display: flex;
    height: 42vh;
    font-family: 'DM Serif Display';
    background-repeat: no-repeat;
    background-image: url("../images/backgroung/serviceSingleBg.jpg");
    margin-bottom: 10%;
    background-size: cover;
}

.serviceS-header > h1 {
    width: 20%;
    padding: 2% 3% 0.5% 3%;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    align-self: flex-end;
    text-align: center;
    margin: 0 auto;
    color: #292F36;
}

.serviceS-header > h1 > p {
    color: #4D5053;
    font-size: 14px;
    font-family: Jost;
}

/* SERVICE INFO */
/* detail */
.serviceS-info {
    width: 65%;
    margin: 5% auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ss-info-detail {
    display: flex;
    width: 100%;
}

.ss-info-detail > h1 {
    width: 40%;
    margin-right: 5%;
}

.ss-info-detail > p {
    width: 49%;
    color: #4D5053;
    font-family: 'Jost';
}

.ss-info-detail > p > span {
    color: #CDA274;
}

/* brands */
.ss-info-brands {
    width: 100%;
    margin: 5% 0;
}

.ss-info-brands > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.ss-info-brands > ul > ol {
    width: 12%;
}

.ss-info-brands > ul > ol > img {
    width: 100%;
}

/* video */
.ss-info-video {
    width: 100%;
    border-radius: 20%;
    margin: 3% 0;
}

.ss-info-video > .react-player > div {
    border-radius: 60px;
}

/* Adjustments for text and layout */
.ss-pointers {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Ensure columns have enough space between them */
    flex-wrap: wrap; /* Allow wrapping if columns don't fit */
}

.ss-pointer {
    flex: 1;
    max-width: 45%; /* Ensure columns do not exceed 45% of container width */
}

.ss-pointer > ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.ss-pointer > ul > li {
    counter-increment: li;
    line-height: 30px;
    font-family: 'Jost';
    color: #4D5053;
    padding-left: 0; /* Увеличиваем отступ для основного текста */
    position: relative;
    word-wrap: break-word; /* Ensure long words are wrapped */
    overflow-wrap: break-word; /* Ensure compatibility with various browsers */
}

.ss-pointer > ul > li::before {
    content: counter(li) ". ";
    color: #CDA274;
    margin-right: 5px;
    position: absolute;
    left: -30px;
}

.ss-pointer > ul > li ul {
    list-style-type: disc;
    margin-left: 20px;
    padding-left: 20px;
}

.ss-pointer > ul > li ul > li {
    counter-increment: none;
}

/* GO PORTFOLIO */
.go-portfolio {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5% 0;
}

.gp-img {
    width: 50%;
    object-fit: cover;
}

.gp-img > img {
    width: 100%;
    border-radius: 30px;
}

.gp-text {
    width: 40%;
}

.gp-text > p {
    font-family: 'Jost';
    color: #4D5053;
}

.gp-text > a {
    display: flex;
    width: 40%;
}

.gp-text > a > button {
    width: 100%;
    padding: 8% 2%;
    border-radius: 15px;
    font-family: 'Jost';
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #292F36;
    color: white;
}

/* EXPERIENCE */
.experience {
    width: 70%;
    display: flex;
    justify-content: space-between;
    background-color: #F4F0EC;
    padding: 5% 15%;
    margin: 5% auto 7% auto;
}

.experience > div {
    text-align: center;
}

.experience > div > p:last-child {
    font-family: 'Jost';
}

.experience > div > .num {
    font-family: 'Dm Serif Display';
    font-size: 45px;
    margin: 0;
    color: #CDA274;
}

.s-project {
    border-right: 1px solid #CDA274;
    border-left: 1px solid #CDA274;
    padding: 0% 8%;
    margin: 0 6% 0 6%;
}

.a-project {
    border-right: 1px solid #CDA274;
    padding: 0% 8% 0 0;
    margin: 0 8% 0 0;
}

/* Mobile and Tablet Styles */
@media screen and (max-width: 768px) {
    .serviceS-header {
        height: 35vh;
    }

    .serviceS-header > h1 {
        width: 80%;
        padding: 15px 20px;
        font-size: 20px;
    }

    .serviceS-info {
        width: 90%;
    }

    .ss-info-detail {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .ss-info-detail > h1,
    .ss-info-detail > p {
        width: 100%;
        margin-right: 0;
    }

    .ss-info-brands > ul {
        flex-direction: column;
        align-items: center;
    }

    .ss-info-brands > ul > ol {
        width: 50%;
        margin-bottom: 10px;
    }

    .ss-pointers {
        flex-direction: column;
        gap: 20px;
    }

    .ss-pointer {
        max-width: 100%;
    }

    .go-portfolio {
        flex-direction: column;
        align-items: center;
    }

    .gp-img,
    .gp-text {
        width: 100%;
    }

    .gp-text > a {
        width: 80%;
        margin: 0 auto;
    }

    .experience {
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 10% 5%;
    }

    .s-project,
    .a-project {
        border: none;
        padding: 0;
        margin: 0;
    }

    .experience > div {
        margin-bottom: 20px;
    }

    .experience > div > .num {
        font-size: 36px;
    }
}

@media screen and (max-width: 480px) {
    .serviceS-header > h1 {
        font-size: 16px;
    }

    .serviceS-info {
        width: 95%;
    }

    .ss-info-detail > h1 {
        font-size: 1.3rem;
    }

    .ss-info-detail > p {
        font-size: 1rem;
    }

    .ss-info-brands > ul > ol {
        width: 60%;
    }

    .gp-text > a > button {
        padding: 12% 5%;
    }

    .experience > div > .num {
        font-size: 30px;
    }
}
