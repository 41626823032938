.restricted {
    min-height: 25vh;
    justify-content: center;
    margin-bottom: 10%;
}

.restricted-header {
    display: flex;
    height: 42vh;
    font-family: 'DM Serif Display';
    background-repeat: no-repeat;
    background-image: url("../images/backgroung/restrictedBg.jpg");
    margin-bottom: 10%;
    background-size: cover;
}

.restricted-header>h1{
    width: 20%;
    padding: 2% 3% 0.5% 3%;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    align-self: flex-end;
    text-align: center;
    margin: 0 auto;
    color: #292F36;
}

.restricted-header>h1>p {
    color: #4D5053;
    font-size: 14px;
    font-family: Jost;
}

.passwordPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    background-color: #F4F0EC;
    border-radius: 40px;
    padding: 5% 0%;
    margin: 0 auto;
}

.p-text-part {
    width: 55%;
    text-align: center;
}

.passwordPart>h1 {
    font-family: 'DM Serif Display';
    color: #292F36;
}

.passwordPart>.p-text-part>p{
    color: #4D5053;
    font-family: Jost;
}

.enterPassword {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    align-items: center;
}

.enterPassword>input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    outline: none;
    border-bottom: 1px solid black;
    background-color: transparent;
    margin-top: 7%;
    height: 25px;
    width: 100%;
}

.enterPassword>button{
    padding: 5% 0%;
    width: 40%;
    border-radius: 15px;
    background-color: #292F36;
    color: white;
    margin-top: 20%;
    margin-bottom: 15%;
    display: flex;
    justify-content: center;
}