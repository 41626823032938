.op-project {
  display: flex;
  flex-direction: column;
  max-width: 45%;
  margin: 2% 2%;
  flex: 1 1 310px;
  cursor: pointer; /* Добавляем курсор указателя для всей карточки */
}

.op-pro-img {
  width: 100%;
  object-fit: cover;
}

.op-pro-img > img {
  border-radius: 30px;
  width: 100%;
}

.op-pro-detail {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.op-pro-info {
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
}

.op-prj-title {
  font-family: "Dm Serif Display";
  font-size: 18px;
  margin: 8% 0 4% 0;
  width: 100%;
}

.op-prj-path {
  width: 100%;
  font-size: 14px;
  font-family: "Jost";
  margin: 0;
  width: 50%;
}

.op-pro-btn {
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.op-pro-btn > a {
  width: 70%;
  display: flex;
  justify-content: center;
}

.op-pro-btn > a > button {
  width: 100%;
  border-radius: 50%;
  padding: 27%;
  background-color: #f4f0ec;
  border: none;
  font-size: 18px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

/* Mobile and Tablet Styles */
@media screen and (max-width: 768px) {
  .op-project {
    max-width: 100%;
    margin: 10px 0;
  }

  .op-prj-title {
    font-size: 16px;
  }

  .op-pro-btn > a > button {
    padding: 20%;
  }
}

@media screen and (max-width: 480px) {
  .op-prj-title {
    font-size: 14px;
    margin: 5% 0 2% 0;
  }

  .op-prj-path {
    font-size: 12px;
  }

  .op-pro-btn > a > button {
    padding: 15%;
    font-size: 16px;
  }
}
