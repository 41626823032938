.footer {
    width: 100%;
    bottom: 0;
    right: 0;
}

.f-info {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.f-social-medias {
    width: 30%;
    padding-right: 8%;
}

.f-pages, .f-services {
    width: 19%;
}

.f-logo-text {
    font-family: 'DM Serif Display';
    font-size: 22px;
    display: flex;
    flex-direction: row;
    align-items: center; /* Выравниваем логотип и текст по вертикали */
}

.f-logo-text > a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center; /* Центрируем текст и изображение логотипа по вертикали */
}

.f-text {
    width: 80%;
}

.f-logo {
    display: flex;
    width: auto;
    align-items: center;
    margin-right: 15px; /* Добавляем отступ справа, чтобы логотип и цитата не сливались */
}

.f-logo > img {
    width: 100%;
    max-width: 290px; /* Максимальная ширина для логотипа на больших экранах */
    height: auto; /* Сохраняем пропорции изображения */
}

.f-about-text > p {
    margin: 0;
    font-family: Jost;
    font-weight: 300;
    text-align: left; /* Выровняем текст по левому краю */
    max-width: 100%; /* Цитата может занимать всю ширину контейнера */
    line-height: 1.4; /* Увеличиваем межстрочный интервал для лучшей читаемости */
}

.f-smedia {
    margin: 8% 0;
}

.f-smedia > ul {
    display: flex;
    padding: 0;
}

.f-smedia > ul > li {
    list-style-type: none;
    margin-right: 15%;
}

.f-pages > p:first-child,
.f-services > p:first-child,
.f-contact > p:first-child {
    font-family: 'DM Serif Display';
    font-size: 18px;
    color: #292F36;
}

.f-pages > ul > li > a,
.f-services > ul > li > a,
.f-contact > p > a,
.f-contact > p {
    font-family: 'Jost';
    font-size: 15px;
    color: #4D5053;
}

.f-pages > ul > li > a:hover,
.f-services > ul > li > a:hover {
    color: black;
}

.f-pages > ul > li,
.f-services > ul > li {
    margin-bottom: 10%;
    list-style-type: none;
}

.f-pages > ul,
.f-services > ul {
    padding: 0;
}

/* TERMS */
.terms {
    width: 60%;
    margin: 0 auto;
    display: flex;
}

.terms > ul {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 5% 0 0 0;
}

.terms > ul > ol > a {
    color: #4D5053;
    font-size: 14px;
    padding: 0;
}

/* CONTACT */
.f-contact {
    width: 21%;
    color: #4D5053;
    font-family: 'Jost';
}

/* COPY RIGHT */
.f-copyright {
    text-align: center;
    color: #4D5053;
    font-family: 'Jost';
    font-size: 14px;
    margin: 2% 0% 5% 0;
}

/* Адаптивность для планшетов и небольших экранов */
@media screen and (max-width: 768px) {
    .f-social-medias {
        width: 28%;
        padding-right: 5%;
    }

    .f-logo > img {
        max-width: 220px; /* Уменьшаем размер логотипа на планшетах */
    }

    .terms {
        width: 100%;
    }
}

/* Адаптивность для мобильных устройств */
@media screen and (max-width: 480px) {
    .f-logo > img {
        max-width: 150px; /* Уменьшаем размер логотипа на мобильных устройствах */
    }

    .footer {
        padding: 15px 0;
        background-color: #f9f9f9;
        text-align: center; /* Центрируем весь текст внутри футера */
    }

    .f-info {
        width: 100%; /* Увеличиваем ширину до 100% */
        flex-direction: column;
        align-items: center;
        text-align: center; /* Центрируем текст */
    }

    .f-social-medias, .f-pages, .f-services, .f-contact {
        width: 100%;
        text-align: center;
        margin-bottom: 15px; /* Добавляем отступы между секциями */
    }

    .f-logo-text {
        flex-direction: column;
        justify-content: center;
        align-items: center; /* Центрируем логотип */
        margin-bottom: 10px;
        text-align: center; /* Центрируем текст под логотипом */
    }

    .f-about-text > p {
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
        max-width: 90%; /* Ограничиваем ширину текста для лучшей читаемости */
        line-height: 1.4; /* Увеличиваем межстрочный интервал */
        margin: 0 auto; /* Центрируем сам текст внутри родителя */
    }

    .f-smedia {
        justify-content: center;
        margin: 10px 0;
    }

    .f-smedia > ul {
        justify-content: center;
        padding: 0;
        display: flex;
        flex-wrap: nowrap; /* Убираем перенос строк для иконок соц. сетей */
        list-style: none; /* Убираем точки перед иконками соц. сетей */
    }

    .f-smedia > ul > li {
        margin-right: 10px; /* Уменьшено пространство между иконками */
        font-size: 18px; /* Увеличиваем размер иконок соц. сетей */
    }

    .f-pages > p:first-child, .f-services > p:first-child, .f-contact > p:first-child {
        text-align: center;
        font-size: 16px; /* Уменьшаем размер шрифта */
        margin-bottom: 10px;
    }

    .f-pages > ul > li, .f-services > ul > li, .f-contact > p, .f-contact > p > a {
        text-align: center;
        font-size: 14px; /* Уменьшаем размер шрифта */
        margin-bottom: 5px; /* Уменьшаем отступы между элементами */
    }

    .f-pages > ul, .f-services > ul {
        padding: 0;
        text-align: center;
        list-style-type: none; /* Убираем точки перед списками */
    }

    .terms {
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }

    .terms > ul {
        display: flex;
        justify-content: center;
        flex-direction: column; /* Все элементы в колонку */
        padding: 0;
        align-items: center; /* Центрируем все элементы */
        list-style-type: none; /* Убираем точки перед списками */
    }

    .terms > ul > li {
        margin-bottom: 10px;
        text-align: center; /* Центрируем каждый элемент списка */
    }

    .f-copyright {
        text-align: center;
        font-size: 12px; /* Уменьшаем размер шрифта */
        margin-top: 20px;
    }
}
