.projectDetails {
  width: 100%;
  margin-bottom: 10rem; /* Добавляем отступ снизу от футера */
}

.pDetails-header {
  height: 42vh;
  background-size: cover;
}

.pDetails-header img.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  padding: 8% 0% 5% 0%;
}

.d-client-info {
  width: 37%;
  min-height: 40vh;
  padding: 3% 3%;
  background-color: #f4f0ec;
  border-radius: 15%;
  display: flex;
  justify-content: center;
}

table > tbody > tr {
  color: #4d5053;
}

table > tbody > tr > td:first-child {
  font-family: "DM Serif Display";
  padding-right: 10vh;
}

table > tbody > tr > td:last-child {
  font-family: "Jost";
}

.d-project-info {
  width: 55%;
  margin-left: 5%;
}

.d-project-info > h2 {
  color: #292f36;
  font-family: "DM Serif Display";
  font-size: 30px;
}

.d-project-info > p {
  color: #4d5053;
  font-family: "Jost";
}

.embla {
  position: relative;
  max-width: 80rem; /* Увеличение ширины карусели */
  margin: auto;
  --slide-height: 28rem; /* Высота слайдов */
  --slide-spacing: 1rem;
  --slide-size: 80%; /* Ширина слайдов */
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transition: transform 0.3s ease, opacity 0.2s ease-in-out;
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  cursor: zoom-in;
}

.embla__slide:hover .embla__slide__img {
  transform: scale(1.05); /* Увеличиваем изображение при наведении */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Добавляем тень */
}

.embla__slide__img {
  border-radius: 1.8rem;
  display: block;
  height: calc(var(--slide-height) - 2rem); /* Уменьшаем высоту изображения на 2rem */
  width: calc(100% - 2rem); /* Уменьшаем ширину изображения на 2rem */
  object-fit: cover;
  margin: auto;
  transition: transform 0.3s ease; /* Плавная анимация увеличения */
}

.embla__parallax {
  border-radius: 1.8rem;
  height: 100%;
  overflow: hidden;
}

.embla__parallax__layer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.embla__parallax__img {
  max-width: none;
  flex: 0 0 calc(115% + (var(--slide-spacing) * 2));
  object-fit: cover;
}

/* Стили для модального окна с увеличенным изображением */
.zoomModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.zoomOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.zoomedImage {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.closeZoomButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

/* Обновленные стили для навигационных кнопок */
.zoomNavButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
  border: none;
  color: #fff;
  font-size: 2rem; /* Размер текста (стрелок) 2rem */
  width: 3rem; /* Ширина кнопки */
  height: 3rem; /* Высота кнопки */
  border-radius: 50%; /* Круглая форма кнопки */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
  transition: background 0.3s ease; /* Плавный переход для изменения фона */
}

.zoomNavButton:hover {
  background: rgba(0, 0, 0, 0.7); /* Более темный фон при наведении */
}

.zoomNavButton.prev {
  left: calc(2rem); /* Расположение стрелки влево внутри изображения */
}

.zoomNavButton.next {
  right: calc(2rem); /* Расположение стрелки вправо внутри изображения */
}

.carousel-progress {
  position: relative;
  margin-top: 10px; /* Отступ сверху, если под каруселью */
  height: 5px; /* Высота прогресс-бара */
  background: rgba(69, 66, 66, 0.3);
  border-radius: 5px;
  overflow: hidden;
  left: 50%; /* Центрирование по горизонтали */
  transform: translateX(-50%); /* Центрирование по горизонтали */
}

.carousel-progress-bar {
  height: 100%;
  width: 0;
  background: linear-gradient(90deg, rgba(220, 9, 9, 0) 0%, #dc0909 100%);
  animation: progressBar 3s linear infinite;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Mobile and Tablet Styles */
@media screen and (max-width: 768px) {
  .details {
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    padding: 10% 0 5% 0;
  }

  .d-client-info {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  table > tbody > tr > td:first-child {
    padding-right: 5vh;
  }

  .d-project-info {
    width: 100%;
    margin-left: 0;
  }

  .embla {
    --slide-height: 20rem; /* Уменьшаем высоту слайдов */
  }

  .embla__slide__img {
    height: calc(var(--slide-height) - 1rem); /* Уменьшаем высоту изображения на 1rem */
    width: calc(100% - 1rem); /* Уменьшаем ширину изображения на 1rem */
  }

  .zoomNavButton {
    font-size: 1.5rem; /* Уменьшаем размер текста (стрелок) */
    width: 2.5rem; /* Уменьшаем ширину кнопки */
    height: 2.5rem; /* Уменьшаем высоту кнопки */
  }

  .carousel-progress {
    height: 3px; /* Уменьшаем высоту прогресс-бара */
  }
}

@media screen and (max-width: 480px) {
  .details {
    padding: 15% 0 5% 0;
  }

  .d-project-info > h2 {
    font-size: 24px;
  }

  .embla {
    --slide-height: 15rem; /* Ещё больше уменьшаем высоту слайдов */
  }

  .embla__slide__img {
    height: calc(var(--slide-height) - 0.5rem); /* Уменьшаем высоту изображения на 0.5rem */
    width: calc(100% - 0.5rem); /* Уменьшаем ширину изображения на 0.5rem */
  }

  .zoomNavButton {
    font-size: 1rem; /* Ещё больше уменьшаем размер текста (стрелок) */
    width: 2rem; /* Ещё больше уменьшаем ширину кнопки */
    height: 2rem; /* Ещё больше уменьшаем высоту кнопки */
  }

  .carousel-progress {
    height: 2px; /* Ещё больше уменьшаем высоту прогресс-бара */
  }
}

/* Скрытие хедера при открытом модальном окне */
.modal-open .header {
  display: none; /* Скрываем хедер */
}
