.faq {
    text-align: center;
}

.faq-header {
    display: flex;
    height: 42vh;
    font-family: 'DM Serif Display';
    background-repeat: no-repeat;
    background-image: url("../images/backgroung/faqBg.jpg");
    margin-bottom: 10%;
    background-size: cover;
}

.faq-header>h1 {
    width: 20%;
    padding: 2% 3% 0.5% 3%;
    background-color: white;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    align-self: flex-end;
    text-align: center;
    margin: 0 auto;
    color: #292F36;
}

.faq-header>h1>p {
    color: #4D5053;
    font-size: 14px;
    font-family: Jost;
}

/* FAQ Block Styling */
.faq-title {
    margin: 0% 0% 5% 0%;
}

.faq-block {
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 0 auto;
    padding-bottom: 10%;
}

.faq-block.left .ep-questions {
    order: 1;
}

.faq-block.left .ep-q-img {
    order: 2;
    margin-left: 5%;
}

.faq-block.right .ep-questions {
    order: 2;
    margin-left: 5%;
}

.faq-block.right .ep-q-img {
    order: 1;
}

.ep-questions {
    width: 60%;
}

.ep-q-img {
    width: 40%;
}

.q-title {
    font-family: 'DM Serif Display';
    border-bottom: 1px solid #CDA274;
    padding: 1% 0% 6% 0%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #292F36;
}

.q-title:hover {
    color: #CDA274;
}

.q-title>svg {
    margin-left: 3%;
}

.q-content {
    text-align: left;
}

.ep-q-img img {
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
}

/* Адаптивность для мобильных устройств */
@media screen and (max-width: 480px) {

    .faq-header {
        height: 30vh;
    }

    .faq-header>h1 {
        width: 80%;
        padding: 10px 20px;
        font-size: 18px;
    }

    .faq-block {
        flex-direction: column;
        width: 90%;
        padding-bottom: 5%;
    }

    .faq-block .ep-questions,
    .faq-block .ep-q-img {
        width: 100%;
    }

    .faq-block .ep-q-img {
        margin-left: 0;
        margin-top: 20px;
    }

    .faq-title {
        font-size: 24px;
    }

    .q-title {
        font-size: 18px;
        padding: 10px 0;
    }

    .q-content {
        font-size: 16px;
        padding: 10px 0;
    }
}
